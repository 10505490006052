export default class ActionRequestEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#action_request_uuid") ? (elem.querySelector("#action_request_uuid") as HTMLInputElement).value : null,
            card_id: elem.querySelector("#new_action_request_card_id") ? (elem.querySelector("#new_action_request_card_id option:checked") as HTMLInputElement).value :  (elem.querySelector("#action_request_card_id option:checked") as HTMLInputElement).value,
            ticket_number: elem.querySelector("#action_request_ticket_number").value,
            visitor_name: elem.querySelector("#action_request_visitor_name").value,
            visitor_company: elem.querySelector("#action_request_visitor_company").value,
            reasoning: elem.querySelector("#action_request_reasoning").value,
            plan_from: elem.querySelector("#action_request_plan_from").value,
            plan_to: elem.querySelector("#action_request_plan_to").value,
            sam_number: elem.querySelector("#action_request_sam_number").value,
            coord: elem.querySelector("#action_request_coord").value,
            serial: elem.querySelector("#action_request_serial").value,
            grid: elem.querySelector("#action_request_grid").value
        }
    }
}